<!--保存-->
<template>
  <el-dialog :visible.sync="show" width="40%" title="新增页面">
    <div class="flex-column col-center">
      <!-- form -->
      <el-form :model="form" ref="form" label-width="90px" style="width: 80%">
        <el-form-item label="页面标题" prop="title" verify>
          <el-input type="text" v-model="form.title" size="small" />
        </el-form-item>
        <el-form-item label="页面描述" prop="remarks" class="form-item-none">
          <el-input v-model="form.remarks" maxlength="30" placeholder="输入备注" show-word-limit type="textarea"
            size="small" />
        </el-form-item>
      </el-form>
    </div>

    <!-- submit -->
    <div slot="footer" class="dialog-footer flex-center">
      <el-button type="primary" round style="width: 140px" @click="submit">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addWidgetPage } from '@/api/project'

export default {
  name: "CreatePageDialog",

  data() {
    return {
      show: false,
      form: {
        title: '',
        remarks: ''
      },
    };
  },
  mounted() {
  },

  methods: {
    open(id) {
      this.$nextTick(() => {
        this.$refs["form"]?.resetFields()
      })
      this.show = true;
      this.form.title = ''
      this.form.remarks = ''
      this.form.shopid = id
    },

    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addPage();
        }
      });
    },
    /**
     * 创建商城模版
     */
    addPage() {
      const data = {
        ...this.form,

      }
      addWidgetPage(data).then(res => {
        if (res) {
          this.$emit('ok', res)
          this.show = false
        }
      })
    },

  },
};
</script>

<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 12px;

  .el-form-item__label {
    text-align: left;
  }

  // .el-form-item__error {
  //   top: 47px;
  //   left: -80px;
  // }
}

:deep(.el-button--primary) {
  background: $color-theme !important;
}

.form-item-none {
  border-color: transparent;
}
</style>