<!--商城页面-->
<template>
  <div class="body">
    <div class="content">
      <el-button type="primary" @click="addPage">新增页面</el-button>

      <div class="content-head">
        <div @click="handleSelectFile">
          <el-image class="radius home_page_logo" :src="homePage.cover" alt="" />
        </div>
        <div class="flex-column home_page_des">
          <div class="flex col-center">
            <span class="mr8 f15 f-bold">{{ homePage.Title }}</span>
            <div class="p4 r3 bg-theme f13 f-white">首页</div>
          </div>
          <!-- <span class="f14 f-theme pointer" v-if="homePage.PageID" @click="editPage(homePage.PageID)">编辑</span> -->
        </div>
      </div>

      <div class="mt30" style="height: calc(100% - 165px);">
        <el-table :data="projectPages" border style="width: 100%" v-loading="tabLoading" element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading" height="100%" :header-cell-style="{
        background: '#f7f8fa',
        color: '#323233',
        fontWeight: 500,
      }">
          <el-table-column prop="Title" label="标题">
            <template slot-scope="scope">
              <img v-show="scope.row.IsHome" class="h16 w16 mr5" src="https://b.yzcdn.cn/channel-icon/h5-mobile.png"
                alt="" />
              <span>{{ scope.row.Title }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="pageTypeStr" label="页面状态">
          </el-table-column>
          <el-table-column prop="Remarks" label="备注">

          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="editPage(scope.row.PageID)">编辑</el-button>
              <el-button type="text" :disabled="scope.row.IsHome"
                @click="setHomePage(scope.row.PageID)">设为主页</el-button>
              <el-button type="text" @click="handleDelete(scope.row, scope.$index)"
                :disabled="scope.row.IsHome">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination_wrap">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
            :page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <CreatePageDialog ref="createPageDialogRef" @ok="getProjectPages" />

    <UploadDialog ref="uploadDiaRef" @select="handleSelect" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import CreatePageDialog from "@/components/CreatePageDialog";
import UploadDialog from '@/components/UploadDialog'

import { deleteWidgetPage, getWidgetPages, setHomePage, getThemePageWidgets, updateTemplateCover } from '@/api/project'
export default {
  name: "mall-pages",
  components: {
    CreatePageDialog,
    UploadDialog
  },
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      projectPages: [],
      tabLoading: false,
      defaultImg: 'https://img.yzcdn.cn/public_files/2016/05/13/8f9c442de8666f82abaf7dd71574e997.png!small.webp',
      homePage: {}
    };
  },
  computed: {
    ...mapGetters(["project"]),
    // homePage() {
    //   return this.projectPages?.find((page) => page.IsHome) || { Title: '暂无首页' };
    // },
  },
  created() {
    this.getProjectPages()
  },
  methods: {
    ...mapMutations(["setCurPage"]),

    handleSelect(list) {
      const _data = {
        cover: list[0]
      }
      updateTemplateCover(_data).then(res => {
        if (res) {
          this.$message({
            type: "success",
            message: "修改成功~~",
          });
          this.homePage.cover = res
          // this.projectPages = this.projectPages?.map(f => {
          //   if (f.IsHome) {
          //     f.cover = res
          //   }
          //   return { ...f }
          // })
        }
      })
    },
    /**打开文件选择弹框 */
    handleSelectFile() {
      this.$nextTick(() => {
        this.$refs["uploadDiaRef"].open()
      })
    },
    /**页数 change */
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getProjectPages()
    },

    /**size change */
    handleSizeChange(val) {
      this.pageSize = val
      this.getProjectPages()
    },

    /**删除page */
    handleDelete(row, index) {
      console.log(row)
      const data = {
        pageid: row.PageID
      }
      deleteWidgetPage(data).then(res => {
        if (res) {
          this.projectPages.splice(index, 1)
        }
      })
    },

    /**获取当前项目的page列表 */
    getProjectPages() {
      const _data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
      this.tabLoading = true
      const shopID = this.project.MartID;
      getWidgetPages(shopID, _data).then(res => {
        this.homePage = {
          Title: res.Name,
          cover: res.cover
        }
        this.projectPages = [...res.List]
        this.total = res.TotalCount
      }).finally(() => {
        this.tabLoading = false
      })
    },

    /**新增页面 */
    addPage() {
      this.$refs["createPageDialogRef"].open(this.project.MartID,)
    },

    /**复制页面 */
    copyPage(data) {
      let page = this.$cloneDeep(data);
      page.id = this.$getRandomCode(4);
      page.home = false;

      this.project.pages.push(page);
    },

    /**编辑页面 */
    editPage(id) {
      const _data = {
        shopID: this.project.MartID,
        pageID: id
      }
      return getThemePageWidgets(_data).then(res => {
        if (res) {
          const _res = this.$cloneDeep(res)
          _res.componentList = _res.componentList?.map(m => {
            if (m.component == 'McRecommendedGoods') {
              m.type = {
                productType: {
                  id: m.productType,
                  iRecommendIDd: m.RecommendID,
                  featureIDs: m.featureIDs,
                }
              }
            }
            if (m.component == 'McActivityGroup') {
              m.list = {
                type: {
                  id: m.type,
                  iRecommendIDd: m.RecommendID,
                  promotionIDs: m.promotionIDs,
                }
              }
            }
            if (m.component == 'McSwiper') {
              m.styles.componentHeight = m.height
              m.styles.MarginTop = m.MarginTop
            } else {
              if (m.space) {
                m.space.MarginTop = m.MarginTop
              } else {
                m.space = {
                  MarginTop: m.MarginTop
                }
              }
            }
            // 转化热区比例到750
            if (m.component == 'McImgHot') {
              const _list = [...m.imageValue.HotspotList]
              m.imageValue.HotspotList = _list.map(_m => {
                _m.top = _m.top * 750 / m.imageValue.naturalWidth
                _m.left = _m.left * 750 / m.imageValue.naturalWidth
                _m.width = _m.width * 750 / m.imageValue.naturalWidth
                _m.height = _m.height * 750 / m.imageValue.naturalWidth
                return { ..._m }
              })
            }
            return { ...m }
          })
          this.setCurPage(_res)
          this.$router.push({
            name: "page-build"
          });
        }
      })
    },

    /**设置主页 */
    setHomePage(PageID) {
      const _data = {
        shopID: this.project.MartID,
        pageID: PageID
      }
      setHomePage(_data).then(res => {
        if (res) {
          this.getProjectPages()
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 0;
  font-size: 13px;
}

.body {
  padding: 15px;
  height: 100%;

  .content {
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    background: #fff;
    box-sizing: border-box;

    .pagination_wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .content-head {
      display: flex;
      // align-items: flex-start;
      align-items: center;
      padding: 20px 30px;
      margin-top: 15px;
      background: #f7f8fa;

      .home_page_des {
        align-items: center;
        justify-content: center;
      }

      .home_page_logo {
        cursor: pointer;
        display: block;
        width: 50px;
        height: 50px;
        border: 1px solid #155bd4;
        margin-right: 10px;
        background-color: #fff;
      }
    }
  }
}
</style>